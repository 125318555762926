@font-face {
  font-family: "DellaRespira";
  font-style: normal;
  font-weight: normal;
  src: local("DellaRespira"),
    url("./assets/fonts/DellaRespira-Regular.ttf") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Raleway Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Raleway Regular"),
    url("./assets/fonts/Raleway-VariableFont_wght.ttf") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Italiana Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Italiana Regular"),
    url("./assets/fonts/Italiana-Regular.ttf") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assets/fonts/Agustina.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  /* font: 19px / 23px Raleway Regular, "Raleway Regular", sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}
