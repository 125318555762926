@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 4rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
}
.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}

.animation { 
  height: auto;
  margin: -10vw auto;
}
/* Media Query */
@media (max-width: 768px) {
  .animation { 
    margin: -10vh auto;
  }
}

