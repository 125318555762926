@import "../../_globalColor";

.project-title {
  font-size: 52px;
  font-weight: 400;
  line-height: normal;
}

.startup-project-text img {
  max-width: 100%;
  height: auto;
}

.repo-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem 1rem;
}

/* Media Query */
@media (max-width: 1380px) {
  .project-title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .project-title {
    font-size: 30px;
    text-align: center;
  }

  .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Adjust the number of columns as needed */
  gap: 20px;
  /* Adjust the gap between cards */
}

.github-project-card {
  display: flex;
  flex-direction: column;
  // background-color: $lightBackground2;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.github-project-card:hover {
  transform: scale(1.05);
}

.github-project-card-light {
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.github-project-card-dark {
  box-shadow: 0px 10px 30px -15px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.github-project-card img {
  width: 150px;
  /* Adjust the image size as needed */
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.github-project-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.github-project-card p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

// new stuff

.github-project-card .languages-used {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.github-project-card .languages-used .language {
  font-size: 12px;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 4px;
}

/* Different colors for each programming language */

.github-project-card .languages-used .javascript {
  background-color: #f0db4f;
  color: #000;
}

.github-project-card .languages-used .python {
  background-color: #3776ab;
  color: #fff;
}

.github-project-card .languages-used .java {
  background-color: #b07219;
  color: #fff;
}

.github-project-card .languages-used .flutter {
  background-color: #02569b;
  color: #fff;
}

.github-project-card .languages-used .dart {
  background-color: #0175c2;
  color: #fff;
}

.github-project-card .languages-used .firebase {
  background-color: #f7c52b;
  color: #000;
}

.github-project-card .languages-used .numpy {
  background-color: #013243;
  color: #fff;
}

.github-project-card .languages-used .matplotlib {
  background-color: #11557c;
  color: #fff;
}

.github-project-card .languages-used .tensorflow {
  background-color: #ff6f00;
  color: #fff;
}

.github-project-card .languages-used .react {
  background-color: #61dafb;
  color: #000;
}

.github-project-card .languages-used .flask {
  background-color: #000000;
  color: #fff;
}

.github-project-card .languages-used .react-native {
  background-color: #61dafb;
  color: #000;
}

.github-project-card .languages-used .nltk {
  background-color: #333333;
  color: #fff;
}

.github-project-card .languages-used .vuejs {
  background-color: #42b883;
  color: #fff;
}

.github-project-card .languages-used .llms {
  background-color: #ff4500;
  color: #fff;
}

.github-project-card .languages-used .intersystems-iris {
  background-color: #1f72bd;
  color: #fff;
}

.github-project-card .languages-used .bun {
  background-color: #3eaf7c;
  color: #fff;
}

.github-project-card .languages-used .nextjs {
  background-color: #000000;
  color: #fff;
}

.github-project-card .languages-used .fastapi {
  background-color: #009688;
  color: #fff;
}

.github-project-card .languages-used .ocaml {
  background-color: #ef7a00;
  color: #fff;
}

/* ...existing CSS code... */

/* "Learn More" link styles */

.github-project-card a {
  font-size: 12px;
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.github-project-card a:hover {
  color: #007bff;
}

/* Responsive styles */

@media (max-width: 1380px) {
  .project-title {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .project-title {
    font-size: 30px;
    text-align: center;
  }

  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .project-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .github-project-card {
    text-align: center;
  }
}
